<template>
  <section>
    <h1 v-show="false">Oniros</h1>
    <logo/>

    <h2>{{ $t('security.login.title') }}</h2>

    <v-form>
      <v-text-field
          :label="$t('security.login.inputs.username')"
          prepend-inner-icon="mdi-account-outline"
          v-model="user.username"
          solo
      />
      <v-text-field
          :label="$t('security.login.inputs.password')"
          :type="textFieldType"
          v-model="user.password"
          prepend-inner-icon="mdi-lock-outline"
          solo
      >
        <template v-slot:append>
          <v-icon @click="passwordVisible = !passwordVisible" tabindex="2">{{
              appendIcon
            }}
          </v-icon>
        </template>
      </v-text-field>
      <footer>
        <div>
          <v-btn :to="{ name: 'RecoveryPassword' }" depressed>
            {{ $t('security.forgottenPassword.button') }}
          </v-btn>
        </div>
        <div>
          <v-btn :to="{ name: 'RecoveryLogin' }" depressed>
            {{ $t('security.forgottenLogin.button') }}
          </v-btn>
        </div>
        <div>
          <v-btn type="submit" @click.prevent="onSubmitForm" color="primary" depressed>
            {{ $t('general.buttons.login') }}
          </v-btn>
          <v-btn type="cancel" @click.prevent="onResetForm" depressed>
            {{ $t('general.buttons.cancel') }}
          </v-btn>
        </div>
      </footer>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/components/Template/Logo'

export default {
  name: 'SecurityLogin',

  components: {
    Logo,
  },

  data: () => ({
    passwordVisible: false,
    user: {
      password: '',
      username: '',
    },
  }),

  computed: {
    ...mapGetters(['accessJWTBody']),
    appendIcon () {
      return this.passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
    },
    textFieldType () {
      return this.passwordVisible ? 'text' : 'password'
    },
  },

  methods: {
    ...mapActions(['displaySnackbar', 'login']),
    onResetForm () {
      this.user.username = ''
      this.user.password = ''
      this.passwordVisible = false
    },
    onSubmitForm () {
      this.login(this.user)
          .then(() => {
            if (
                this.accessJWTBody.hasTempPassword ||
                this.accessJWTBody.hasExpiredPassword
            ) {
              this.$router.push({ name: 'ResetPassword' })
            } else if (this.$router.currentRoute.query.redirect !== undefined) {
              this.$router.push({
                path: `${this.$router.currentRoute.query.redirect}`,
              })
            } else {
              this.$router.push({ name: 'Dashboard' })
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              if (Object.keys(error.response.data)
                        .includes('detail')) {
                this.displaySnackbar({
                  status: true,
                  type: 'error',
                  text: this.$t('security.login.alert.wrongPayload'),
                })
                return
              }

              const { error: errorMessage } = error.response.data
              this.displaySnackbar({
                status: true,
                type: 'error',
                text: errorMessage,
              })
            } else {
              this.displaySnackbar({
                status: true,
                type: 'error',
                text: this.$t('security.login.alert.400'),
              })
            }
          })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

section {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (min-width: $s) {
    margin-top: 200px;
  }

  svg {
    width: 300px;
    margin-bottom: 24px;
  }

  h2 {
    text-align: center;
    margin-bottom: 48px;
    color: $logoColor;
    font-size: 45px;
    font-weight: normal;
  }

  form {
    width: 90%;

    @media all and (min-width: $s) {
      width: 80%;
    }

    @media all and (min-width: $m) {
      width: 66%;
    }
  }

  footer {
    div {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      a {
        text-align: right;
        font-size: 1.2rem;
      }

      button {
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
